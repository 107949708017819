<template>
  <div>
    <ToolBar />
    <v-container v-if="video && video.title">
      <div class="px-5">
        <v-row>
          <v-col class="pa-0">
            <h3 class="pt-4">{{video.title}}</h3>
            <v-hover v-slot:default="{ hover }">
              <v-card
                max-width="700px"
                class="d-flex justify-center pb-0 pt-2"
                :elevation="hover ? 12 : 0"
              >
                <youtube :player-width="videoWidth" player-height="360" :video-id="videoId"></youtube>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="4" class="pt-9 mt-8">
            <CardRecents :baseTo="'/depoimento/'" :recents="releaseVideos" :btnViewMore="true" :viewMoreUrl="viewMoreUrl" />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>

import ToolBar from '@/components/ToolBar'
import CardRecents from '@/components/press/CardRecents'
import { getIdFromURL } from 'vue-youtube-embed'

export default {
  mounted() {
    this.$store.dispatch("request", {
      state: "videoBornH2P",
      method: "POST",
      url: "getVideoBornH2p/"+this.$route.params.name
    });
    this.resizeVideo()
    window.addEventListener('resize', () => {
      this.resizeVideo()
    });
  },
  components: {
    ToolBar,
    CardRecents,
  },
  data: () => ({
    videoWidth: 680,
    viewMoreUrl: '/videos'
  }),
  methods: {
    resizeVideo() {
      let windowWidth = window.innerWidth;
      if (windowWidth < 740) {
        this.videoWidth = (windowWidth - 60)

      } else {
        this.videoWidth = 680
      }
    }
  },
  computed: {
    videoId: function () {
      return getIdFromURL(this.video.link)
    },
    video() {
      return this.$store.state.videoBornH2P
    },
    releaseVideos() {
      return this.$store.state.releaseVideosBornH2P
    },
    base_url() {
      return this.$store.state.base_url
    },
  },
  watch: {
    '$route.params.name': function () {
      this.$store.dispatch("request", {
        state: "videoBornH2P",
        method: "POST",
        url: "getVideoBornH2p/"+this.$route.params.name
      });
    }
  }
}
</script>

<style scoped>
.release-title {
  overflow-y: hidden;
  max-height: 80px;
}
.body-news {
  padding-top: 25px;
  padding-bottom: 35px;
}
</style>